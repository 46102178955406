<template>
  <div class="kinsfolkCardList">
    <div class="list-cont" v-if="this.cardList">
      <div v-for="(item, index) in cardList" :key="index" class="card" @click="toDetail(item)">
        <div class="card-infor">
          <div class="headear" v-if="item.headimgurl">
            <img :src="item.headimgurl" alt />
          </div>
          <div class="headear" v-else>
            <img src="../../../../public/static/images/vip/wodexz.png" alt="">
          </div>
          <div class="name-phone">
            <p>{{item.real_name}}</p>
            <p>{{item.mobile}}</p>
          </div>
          <div class="name-phone money">
            <p>
              <span class="font18">剩余{{(item.quota - item.limit_quota).toFixed(2)}}元额度</span>
            </p>
            <p>已使用{{item.limit_quota}}元</p>
          </div>
        </div>
        <div class="operation_btn">
          <div class="btn-one" @click.stop="unbound(item.id)">解除绑定</div>
          <div class="btn-two" @click.stop="show = true,quota ='',now_card_id = item.id">调整额度</div>
        </div>
      </div>
      <div class="addCard">
        <Button
          class="add-btn"
          plain
          hairline
          type="info"
          @click="$router.push('/sendKinsCard')"
        >添加亲属卡</Button>
      </div>
    </div>
    <popup v-model="show" position="bottom" :style="{height: '30%'}" :safe-area-inset-bottom="true">
      <divider>调整金额</divider>
      <field v-model="quota" label="调整后金额" placeholder="请输入调整后金额" />
      <div class="btn_group">
        <Button class="btn" plain type="info" @click="show = false">取消</Button>
        <Button class="btn" plain type="primary" @click="updateQuota">确定</Button>
      </div>
    </popup>
  </div>
</template>

<script>
import Creditshop from "@/api/creditshop";
import { Popup, Field, Divider, Button } from "vant";
export default {
  components: {
    Popup,
    Field,
    Divider,
    Button
  },
  data() {
    return {
      cardList: "",
      show: false,
      now_card_id: "",
      quota: ""
    };
  },
  mounted() {
    this.familyCardList();
  },
  methods: {
    // 亲属卡消费详情
    toDetail(item) {
      this.$router.push({
        path: "/sendfamilyCards",
        query: {
          name: item.real_name,
          id: item.id,
          money: item.quota,
          headimgurl: item.headimgurl
        }
      });
    },
    // 获取亲属卡列表
    async familyCardList() {
      try {
        const res = await Creditshop.familyCardList();
        if (res.code == 200) {
          this.cardList = res.data;
          if (this.cardList.length == 0) {
            this.$router.replace({
              path: "sendKinsCard"
            });
          }
        } else {
          throw res.msg;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 解绑
    async unbound(id) {
      this.$dialog
        .confirm({
          title: "解绑亲属卡",
          message: "是否解绑亲属卡"
        })
        .then(() => {
          try {
            Creditshop.delCard(id).then(res => {
              if (res.code == 200) {
                this.$toast({
                  message: res.msg
                });
                this.familyCardList();
              } else {
                this.$toast({
                  message: res.msg
                });
              }
            });
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    async updateQuota() {
      try {
        const res = await Creditshop.updateQuota(this.now_card_id, this.quota);
        if (res.code == 200) {
          this.$toast({
            message: res.msg
          });
          this.show = false;
          this.familyCardList();
        } else {
          this.$toast({
            message: res.msg
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.kinsfolkCardList {
  height: 100vh;
  background: #fff;

  .list-cont {
    height: 100%;
    padding: 10px;
    .card {
      width: 100%;
      height: 100px;
      border-radius: 5px;
      background: linear-gradient(45deg, #1ec7ed, #4c8cf1);
      padding: 15px 10px 8px;
      margin-bottom: 10px;
      .card-infor {
        display: flex;
      }
      .headear {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #999;
        background: #fff;
        margin-right: 15px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name-phone {
        color: #fff;
        font-size: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          height: 14px;
          line-height: 14px;
        }
        p:nth-child(1) {
          height: 14px;
          font-size: 14px;
        }
      }
      .money {
        flex: 1;
        text-align: right;
        p:nth-child(1) {
          height: 18px;
          font-size: 18px;
        }
        .font18 {
          font-size: 16px;
        }
      }

      .operation_btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        > div {
          border: 1px solid #fff;
          margin-left: 14px;
          border-radius: 5px;
          padding: 5px 10px;
          color: #fff;
        }
      }
    }
  }
  .btn_group {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .btn {
      width: 44%;
    }
  }
  .addCard {
    margin-top: 10px;
    padding: 10px;
    .add-btn {
      width: 100%;
    }
  }
}
</style>
